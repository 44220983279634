import React from "react";

export const ScrollIndicator = () => {
  return (
    <svg width={49.353} height={48.062} viewBox="0 0 49.353 48.062">
      <g>
        <g>
          <path
            d="M9.198 40.669C4.56 36.021.721 29.799.098 22.859s1.958-14.584 6.6-18.57 11.338-4.29 18.038-4.29 13.391.3 18.067 4.29 7.309 11.632 6.359 18.202-5.508 12.043-10.179 16.691-9.466 8.48-14.579 8.85-10.568-2.715-15.206-7.363z"
            fill="#fff"
          />
        </g>
        <g fill="none" strokeWidth={1.8}>
          <path d="M29.813 25.919l-4.906 4.906L20 25.919" stroke="#381300" />
          <path stroke="#43200f" d="M25.098 15v14.053" />
        </g>
      </g>
    </svg>
  );
};
