import React, { FC, useState, useEffect, ReactNode } from "react";
import { colors, fontStacks, fontSizes } from "../global-style";
import { navigate, replace } from "gatsby";

export const Form: FC<{ header: ReactNode; vacancyId?: string }> = ({
  header,
  vacancyId,
}) => {
  const [errors, setErrors] = useState({
    nameEmpty: false,
    phoneNumberEmpty: false,
    emailAddressEmpty: false,
    emailAddressInvalid: false,
    fileEmpty: false,
    noteEmpty: false,
    termsAndConditionsAreNotAccepted: false,
  });

  const [name, setName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [emailAddress, setEmailAddress] = useState("");
  const [file, setFile] = useState<any>();
  const [note, setNote] = useState("");
  const [acceptsTermsAndConditions, setAcceptsTermsAndConditions] = useState(
    false
  );
  const [isLoading, setIsLoading] = useState(false);
  const [isCompleted, setIsCompleted] = useState(false);

  useEffect(() => {
    isCompleted &&
      navigate(
        `/gesolliciteerd/${vacancyId ? `?vacancyid=${vacancyId}` : ""}`,
        {
          replace: true,
        }
      );
  }, [isCompleted]);

  return isCompleted ? (
    <h2 style={{ marginBottom: "0.5rem", textAlign: "center" }}>
      Je hebt gesolliciteerd {vacancyId && "op deze vacature"}
    </h2>
  ) : (
    <>
      {header}

      <form
        onSubmit={e => {
          e.preventDefault();

          if (name.trim() === "") {
            setErrors(errors => ({ ...errors, nameEmpty: true }));
            return;
          }

          if (phoneNumber.trim() === "") {
            setErrors(errors => ({ ...errors, phoneNumberEmpty: true }));
            return;
          }

          if (emailAddress.trim() === "") {
            setErrors(errors => ({ ...errors, emailAddressEmpty: true }));
            return;
          }

          if (
            !/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
              emailAddress
            )
          ) {
            setErrors(errors => ({ ...errors, emailAddressInvalid: true }));
            return;
          }

          if (file === undefined || file === null) {
            setErrors(errors => ({ ...errors, fileEmpty: true }));
            return;
          }

          if (note.trim() === "") {
            setErrors(errors => ({ ...errors, noteEmpty: true }));
            return;
          }

          if (acceptsTermsAndConditions === false) {
            setErrors(errors => ({
              ...errors,
              termsAndConditionsAreNotAccepted: true,
            }));
            return;
          }

          if (Object.values(errors).every(v => v === false)) {
            const formData = new FormData();
            formData.append("name", name);
            formData.append("phoneNumber", phoneNumber);
            formData.append("emailAddress", emailAddress);
            formData.append("file", file);
            formData.append("note", note);
            formData.append("vacancyId", vacancyId);

            setIsLoading(true);

            fetch("https://werkenbijeenbakker.nl/dynamic/apply.php", {
              method: "POST",
              body: formData,
            })
              .then(res => res.text())
              .then(success => {
                setIsLoading(false);
                setIsCompleted(true);
                return console.log(success);
              })
              .catch(error => {
                return console.log(error);
              });
          }
        }}
      >
        <label style={{ display: "block", marginBottom: "0.75rem" }}>
          <p style={{ fontSize: "0.75rem" }}>Naam</p>
          <input
            onChange={e => {
              setName(e.target.value);

              setErrors(errors => ({
                ...errors,
                nameEmpty: false,
              }));
            }}
            value={name}
            style={{
              paddingLeft: "0.5rem",
              paddingRight: "0.5rem",
              lineHeight: "1.5rem",
              width: "100%",
              border: `1px solid ${colors.lightGrey}`,
            }}
          />

          {errors.nameEmpty && (
            <p style={{ color: "darkred", fontSize: "0.75rem" }}>
              naam niet ingevuld
            </p>
          )}
        </label>

        <label style={{ display: "block", marginBottom: "0.75rem" }}>
          <p style={{ fontSize: "0.75rem" }}>Telefoonnummer</p>
          <input
            onChange={e => {
              setPhoneNumber(e.target.value);

              setErrors(errors => ({
                ...errors,
                phoneNumberEmpty: false,
              }));
            }}
            value={phoneNumber}
            style={{
              paddingLeft: "0.5rem",
              paddingRight: "0.5rem",
              lineHeight: "1.5rem",
              width: "100%",
              border: `1px solid ${colors.lightGrey}`,
            }}
          />

          {errors.phoneNumberEmpty && (
            <p style={{ color: "darkred", fontSize: "0.75rem" }}>
              telefoonnummer niet ingevuld
            </p>
          )}
        </label>

        <label style={{ display: "block", marginBottom: "0.75rem" }}>
          <p style={{ fontSize: "0.75rem" }}>E-mailadres</p>
          <input
            onChange={e => {
              setEmailAddress(e.target.value);

              setErrors(errors => ({
                ...errors,
                emailAddressEmpty: false,
                emailAddressInvalid: false,
              }));
            }}
            value={emailAddress}
            style={{
              paddingLeft: "0.5rem",
              paddingRight: "0.5rem",
              lineHeight: "1.5rem",
              width: "100%",
              border: `1px solid ${colors.lightGrey}`,
            }}
          />

          {(errors.emailAddressEmpty && (
            <p style={{ color: "darkred", fontSize: "0.75rem" }}>
              e-mailadres is niet ingevuld
            </p>
          )) ||
            (errors.emailAddressInvalid && (
              <p style={{ color: "darkred", fontSize: "0.75rem" }}>
                e-mailadres is niet correct
              </p>
            ))}
        </label>

        <label style={{ display: "block", marginBottom: "0.75rem" }}>
          <p style={{ fontSize: "0.75rem" }}>CV uploaden</p>
          <input
            type="file"
            onChange={e => {
              setFile(e.target.files[0]);

              setErrors(errors => ({
                ...errors,
                fileEmpty: false,
              }));
            }}
            style={{
              border: `1px solid ${colors.lightGrey}`,
              display: "block",
              width: "100%",
              padding: "0.5rem",
            }}
          />
          {errors.fileEmpty && (
            <p style={{ color: "darkred", fontSize: "0.75rem" }}>
              geen bestand gekozen
            </p>
          )}
        </label>

        <label style={{ display: "block", marginBottom: "0.75rem" }}>
          <p style={{ fontSize: "0.75rem" }}>Toelichting / vraag / opmerking</p>
          <textarea
            onChange={e => {
              setNote(e.target.value);

              setErrors(errors => ({
                ...errors,
                noteEmpty: false,
              }));
            }}
            value={note}
            style={{
              paddingLeft: "0.5rem",
              paddingRight: "0.5rem",
              lineHeight: "1.5rem",
              width: "100%",
              minHeight: "5rem",
              border: `1px solid ${colors.lightGrey}`,
            }}
          >
            {note}
          </textarea>

          {errors.noteEmpty && (
            <p style={{ color: "darkred", fontSize: "0.75rem" }}>
              niet ingevuld
            </p>
          )}
        </label>

        <label
          style={{
            display: "block",
            marginBottom: "1.5rem",
            fontSize: "0.75rem",
          }}
        >
          <input
            type="checkbox"
            style={{ marginRight: "0.5rem" }}
            checked={acceptsTermsAndConditions}
            onChange={e => {
              setAcceptsTermsAndConditions(e.target.checked);

              setErrors(errors => ({
                ...errors,
                termsAndConditionsAreNotAccepted: false,
              }));
            }}
          />
          Ik ga akkoord met de Algemene Voorwaarden
          {errors.termsAndConditionsAreNotAccepted && (
            <p style={{ color: "darkred", fontSize: "0.75rem" }}>
              ga akkoord om de sollicitatie te versturen
            </p>
          )}
        </label>

        {isLoading ? (
          <div
            style={{
              display: "block",
              marginLeft: "auto",
              marginRight: "auto",
              textAlign: "center",
              fontFamily: fontStacks.prequelDemo,
              fontSize: fontSizes[3],
              lineHeight: "1.25",
              backgroundColor: "transparent",
              border: "none",
              transition: "transform 0.2s ease",
              opacity: 0.3,
            }}
          >
            Wordt verzonden...
            <br />
            <span
              style={{
                display: "inline-block",
                transform: "rotate(-3deg)",
              }}
            >
              ————
            </span>
          </div>
        ) : (
          <button
            type="submit"
            style={{
              display: "block",
              marginLeft: "auto",
              marginRight: "auto",
              textAlign: "center",
              fontFamily: fontStacks.prequelDemo,
              fontSize: fontSizes[3],
              lineHeight: "1.25",
              backgroundColor: "transparent",
              border: "none",
              transition: "transform 0.2s ease",
              cursor: "pointer",
            }}
            css={`
              &:hover {
                transform: translateY(-5px);
              }
            `}
          >
            Nu versturen
            <br />
            <span
              style={{
                display: "inline-block",
                transform: "rotate(-3deg)",
              }}
            >
              ————
            </span>
          </button>
        )}
      </form>
    </>
  );
};
