import React from "react";

export const BlobButton = () => (
  <svg width={43.839} height={37.362} viewBox="0 0 43.839 37.362">
    <g>
      <g>
        <path
          d="M35.669 5.746c4.12 3.613 7.53 8.45 8.087 13.845s-1.739 11.337-5.859 14.436-10.071 3.335-16.023 3.335-11.895-.236-16.048-3.335A15.378 15.378 0 01.17 19.878c.844-5.1 4.888-9.362 9.041-12.975S17.619.31 22.161.023s9.389 2.11 13.508 5.723z"
          fill="#f4f5f5"
        />
        <g fill="none" stroke="#381300" strokeWidth={1.6}>
          <path d="M23.78 24.969l4.129-4.13-4.129-4.128" />
          <path d="M27.781 20.799H16" />
        </g>
      </g>
    </g>
  </svg>
);
