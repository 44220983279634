import React, { FC, useState, useEffect } from "react";
import { centerWidth, colors, fontSizes, fontStacks } from "../global-style";
import { graphql, Link } from "gatsby";
import Img, { FluidObject, FixedObject } from "gatsby-image";
import { IndexQuery } from "../graphql-types";
import BackgroundImage from "gatsby-background-image";
import { Center } from "../components/center";
import ReactResizeDetector from "react-resize-detector";
import { BlobButton } from "../components/svg/blob-button";
import { Logo } from "../components/logo";
import { ScrollIndicator } from "../components/svg/scroll-indicator";
import { Button } from "../components/button";
import { Form } from "../components/form";
import { useForm } from "react-hook-form";
import places from "../places.json";
import { intersectionBy, sortBy } from "lodash";

export const query = graphql`
  query Index {
    allVacancy {
      edges {
        node {
          vacancyId
          title
          content
          slug
          lat
          lon
          startDate
          salary
        }
      }
    }

    heroBackground: file(
      relativePath: { eq: "bakery-bar-breads-2253643.png" }
    ) {
      childImageSharp {
        fluid(quality: 80, maxWidth: 1920) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

    pie: file(relativePath: { eq: "pie.png" }) {
      childImageSharp {
        fixed(quality: 80, width: 125) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }

    bread: file(relativePath: { eq: "bread.png" }) {
      childImageSharp {
        fixed(quality: 80, width: 500) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }

    cake: file(relativePath: { eq: "cake.png" }) {
      childImageSharp {
        fixed(quality: 80, width: 110) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }

    croissant: file(relativePath: { eq: "croissant.png" }) {
      childImageSharp {
        fixed(quality: 80, width: 170) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }

    slicedBread: file(relativePath: { eq: "sliced-bread.png" }) {
      childImageSharp {
        fluid(quality: 80) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;

type FilterFormData = {
  place: string;
};

const normalizedPlaces = places.map(place => place.place.trim().toLowerCase());

const getDistanceFromLatLonInKm = (
  lat1: number,
  lon1: number,
  lat2: number,
  lon2: number
) => {
  const R = 6371; // Radius of the earth in km
  const dLat = deg2rad(lat2 - lat1); // deg2rad below
  const dLon = deg2rad(lon2 - lon1);
  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(deg2rad(lat1)) *
      Math.cos(deg2rad(lat2)) *
      Math.sin(dLon / 2) *
      Math.sin(dLon / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  const d = R * c; // Distance in km
  return d;
};

const deg2rad = (deg: number) => {
  return deg * (Math.PI / 180);
};

const IndexPage: FC<{ data: IndexQuery }> = ({ data }) => {
  const isSmallScreen =
    typeof window !== "undefined" && window.innerWidth < 600;

  const [numVisible, setNumVisible] = useState(isSmallScreen ? 6 : 12);
  const [keyword, setKeyword] = useState(
    (typeof localStorage !== "undefined" &&
      localStorage.getItem("werkenbijeenbakker:keyword")) ||
      ""
  );
  const [place, setPlace] = useState<{
    place: string;
    lat: number;
    lon: number;
  }>(
    (typeof localStorage !== "undefined" &&
      localStorage.getItem("werkenbijeenbakker:place") !== null &&
      places.find(
        place =>
          place.place === localStorage.getItem("werkenbijeenbakker:place")
      )) ||
      undefined
  );
  const [withinKm, setWithinKm] = useState(
    (typeof localStorage !== "undefined" &&
      localStorage.getItem("werkenbijeenbakker:withinKm")) ||
      ""
  );

  const { handleSubmit, register, errors } = useForm<FilterFormData>({
    mode: "onBlur",
    defaultValues: {
      place:
        (typeof localStorage !== "undefined" &&
          localStorage.getItem("werkenbijeenbakker:place")) ||
        undefined,
    },
  });

  const onSubmit = (data: FilterFormData) => {
    if (data.place.trim() == "") {
      setPlace(undefined);
      localStorage.removeItem("werkenbijeenbakker:place");
      return;
    }
    setPlace(
      places.find(
        place =>
          place.place.trim().toLowerCase() === data.place.trim().toLowerCase()
      )
    );
    localStorage.setItem(
      "werkenbijeenbakker:place",
      places.find(
        place =>
          place.place.trim().toLowerCase() === data.place.trim().toLowerCase()
      ).place
    );
  };

  const vacanciesPassingKeywordFilter = data.allVacancy.edges.filter(
    edge =>
      edge.node.title
        .toLowerCase()
        .trim()
        .includes(keyword.toLowerCase().trim()) ||
      edge.node.content.includes(keyword.toLowerCase().trim())
  );

  const vacanciesPassingLocationFilter = data.allVacancy.edges.filter(edge => {
    if (place === undefined) {
      return false;
    }

    if (withinKm === "") {
      return true;
    }

    return (
      getDistanceFromLatLonInKm(
        place.lat,
        place.lon,
        edge.node.lat,
        edge.node.lon
      ) <= Number(withinKm)
    );
  });

  const vacancyIdsPassingFilters = (() => {
    if (keyword.trim() === "" && place === undefined) {
      return data.allVacancy.edges;
    }

    if (keyword.trim() !== "" && place === undefined) {
      return vacanciesPassingKeywordFilter;
    }

    if (keyword.trim() === "" && place !== undefined) {
      return vacanciesPassingLocationFilter;
    }

    if (keyword.trim() !== "" && place !== undefined) {
      return intersectionBy(
        vacanciesPassingKeywordFilter,
        vacanciesPassingLocationFilter,
        vacancy => vacancy.node.vacancyId
      );
    }
  })().map(edge => edge.node.vacancyId);

  return (
    <>
      <BackgroundImage
        Tag="header"
        critical={true}
        style={{
          minHeight: "60vh",
          display: "flex",
          flexDirection: "column",
          paddingLeft: "1rem",
          paddingRight: "1rem",
        }}
        fluid={[
          "linear-gradient(rgba(20, 15, 0, 0.47), rgba(20, 15, 0, 0.47))",
          data.heroBackground.childImageSharp.fluid as FluidObject,
        ]}
      >
        <div
          style={{ marginTop: "0.5rem" }}
          css={`
            @media (max-width: 50rem) {
              margin-left: auto;
              margin-right: auto;
              margin-bottom: 0;
              transform: scale(0.9);
            }
          `}
        >
          <Logo />
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            marginTop: "auto",
            marginBottom: "auto",
            textAlign: "center",
          }}
        >
          <h2 style={{ color: colors.yellow }}>
            <div>Werken bij een bakker</div>
            <div
              style={{
                textAlign: "right",
                transform: "rotate(-3deg) translateY(-0.1rem)",
              }}
            >
              —————
            </div>
          </h2>
          <h1
            style={{ maxWidth: "40ch", color: colors.white }}
            css={`
              @media (max-width: 50rem) {
                font-size: 1.5rem;
              }
            `}
          >
            Schrijf je vandaag nog in en ontvang de laatste bakkerij vacatures!
          </h1>
          <Button
            as="a"
            href="#open-application"
            style={{ marginTop: "1.5rem" }}
          >
            Nu inschrijven
          </Button>
        </div>

        <div
          style={{
            marginLeft: "auto",
            marginRight: "auto",
            marginTop: "1.5rem",
            maxWidth: `calc(${centerWidth} + 2.75rem)`,
            width: "100%",
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "flex-start",
          }}
        >
          <div
            style={{
              minHeight: "5rem",
              height: "calc(5vh + 5vw)",
              maxHeight: "10rem",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <button
              style={{
                background: "none",
                border: "none",
                outline: "none",
                cursor: "pointer",
              }}
              css={`
                transition: all 0.15s ease;

                &:hover {
                  transform: translateY(-2px);
                }
              `}
              onClick={() =>
                typeof window !== "undefined" &&
                scroll({ top: window.scrollY + 500 })
              }
            >
              <ScrollIndicator />
            </button>
            <div
              style={{
                width: 1,
                flexGrow: 1,
                marginTop: "0.75rem",
                backgroundColor: colors.lightGrey,
                opacity: 0.5,
              }}
            />
          </div>
        </div>
      </BackgroundImage>

      <main>
        <section
          style={{
            overflow: "hidden",
            backgroundColor: colors.iceGrey,
            paddingTop: "3rem",
            paddingBottom: "3rem",
          }}
        >
          <Center style={{ position: "relative" }}>
            <div
              style={{
                zIndex: 2,
                textAlign: "center",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                marginBottom: ".5rem",
              }}
            >
              <div
                style={{
                  position: "relative",
                  marginBottom: "2rem",
                }}
              >
                <div style={{ display: "inline-block", position: "relative" }}>
                  <h2>Bakker vacatures</h2>

                  <div
                    style={{
                      position: "absolute",
                      top: -25,
                      right: -60,
                    }}
                  >
                    <svg
                      width={52.207}
                      height={52.579}
                      viewBox="0 0 52.207 52.579"
                    >
                      <g>
                        <path
                          d="M31.356 43.396c-4.996 1.333-10.751 1.502-15.73-.803S6.45 35.516 5.556 30.787s1.54-9.42 4.175-13.986 5.484-9.018 10.043-10.638 10.814-.415 14.92 2.823 6.045 8.495 7.373 13.513 2.058 9.794.296 13.427-6.011 6.137-11.007 7.47z"
                          fill="#ffb705"
                        />
                      </g>
                    </svg>

                    <div
                      style={{
                        position: "absolute",
                        top: 0,
                        left: -2,
                        width: "100%",
                        height: "100%",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        fontFamily: fontStacks.prequelDemo,
                        fontWeight: 700,
                      }}
                    >
                      {data.allVacancy.edges.length}
                    </div>
                  </div>
                </div>

                <p style={{ color: colors.darkGrey, maxWidth: "42ch" }}>
                  Bekijk onze actuele en beschikbare bakkerij vacatures en
                  solliciteer vandaag nog.
                </p>

                <Img
                  style={{
                    position: "absolute",
                    zIndex: 1,
                    top: "-0.5rem",
                    left: "-12rem",
                  }}
                  fixed={data.croissant.childImageSharp.fixed as FixedObject}
                />

                <Img
                  style={{
                    position: "absolute",
                    zIndex: 1,
                    top: "-1rem",
                    right: "-18rem",
                  }}
                  fixed={data.cake.childImageSharp.fixed as FixedObject}
                />
              </div>

              <div
                style={{
                  alignSelf: "stretch",
                  backgroundColor: colors.brown,
                  color: colors.iceGrey,
                  marginBottom: "1rem",
                  display: "flex",
                  flexDirection: "column",
                  textAlign: "left",
                  padding: "1rem",
                  borderRadius: "0.25rem",
                  boxShadow: "0px 15px 20px 0px rgba(0, 0, 0, 0.10)",
                }}
              >
                <h3
                  style={{
                    color: colors.yellow,
                    textTransform: "uppercase",
                    fontFamily: fontStacks.prequelDemo,
                    marginBottom: "0.5rem",
                    alignSelf: "center",
                  }}
                >
                  Filter
                </h3>

                <div style={{ overflow: "hidden" }}>
                  <div
                    style={{
                      display: "flex",
                      flexWrap: "wrap",
                      margin: "-0.5rem",
                    }}
                  >
                    {/* keyword filter */}
                    <div style={{ margin: "0.5rem", flexGrow: 1 }}>
                      <label>
                        <p style={{ marginBottom: "0.25rem" }}>
                          Titel / beschrijving:
                        </p>

                        <input
                          style={{
                            padding: "0.75rem",
                            fontSize: "1rem",
                            outline: "none",
                            borderRadius: "0.25rem",
                            border: "none",
                            width: "100%",
                          }}
                          placeholder="b.v. 'Broodbakker'"
                          value={keyword}
                          onChange={e => {
                            setKeyword(e.target.value);
                            localStorage.setItem(
                              "werkenbijeenbakker:keyword",
                              e.target.value
                            );
                          }}
                        />
                      </label>
                    </div>

                    {/* place filter */}
                    <div style={{ margin: "0.5rem", flexGrow: 1 }}>
                      <form onSubmit={handleSubmit(onSubmit)}>
                        <div style={{ overflow: "hidden" }}>
                          <div
                            style={{
                              display: "flex",
                              flexWrap: "wrap",
                              margin: "-0.5rem",
                              alignItems: "flex-end",
                            }}
                          >
                            {/* place */}
                            <div style={{ margin: "0.5rem", flexGrow: 1 }}>
                              <label>
                                <p style={{ marginBottom: "0.25rem" }}>
                                  Locatie:
                                </p>

                                <input
                                  name="place"
                                  ref={register({
                                    validate: (place: string) => {
                                      if (place.trim() === "") {
                                        return true;
                                      }

                                      if (
                                        normalizedPlaces.includes(
                                          place.toLowerCase().trim()
                                        )
                                      ) {
                                        return true;
                                      }

                                      return `Plaats '${place}' niet gevonden`;
                                    },
                                  })}
                                  style={{
                                    padding: "0.75rem",
                                    fontSize: "1rem",
                                    outline: "none",
                                    borderRadius: "0.25rem",
                                    border: "none",
                                    width: "100%",
                                  }}
                                  placeholder="b.v. 'Amsterdam'"
                                />
                              </label>
                            </div>

                            {/* distance */}
                            <div style={{ margin: "0.5rem" }}>
                              <label>
                                <p style={{ marginBottom: "0.25rem" }}>
                                  Afstand:
                                </p>

                                <select
                                  style={{
                                    height: "calc((2 * 0.825rem) + 1rem)",
                                    width: "9rem",
                                    fontSize: "1rem",
                                    border: "none",
                                    outline: "none",
                                    borderRadius: "0.25rem",
                                  }}
                                  value={withinKm}
                                  onChange={e => {
                                    setWithinKm(e.target.value);
                                    localStorage.setItem(
                                      "werkenbijeenbakker:withinKm",
                                      e.target.value
                                    );
                                  }}
                                >
                                  <option value="">Alle afstanden</option>
                                  <option value="5">Binnen 5 km</option>
                                  <option value="10">Binnen 10 km</option>
                                  <option value="15">Binnen 15 km</option>
                                  <option value="25">Binnen 25 km</option>
                                  <option value="50">Binnen 50 km</option>
                                  <option value="100">Binnen 100 km</option>
                                </select>
                              </label>
                            </div>

                            {/* submit */}
                            <div style={{ margin: "0.5rem" }}>
                              <input
                                type="submit"
                                value="Zoeken"
                                style={{
                                  backgroundColor: colors.yellow,
                                  color: colors.black,
                                  fontFamily: fontStacks.prequelDemo,
                                  fontSize: fontSizes[2],
                                  textTransform: "uppercase",
                                  paddingTop: "0.8rem",
                                  paddingBottom: "0.75rem",
                                  paddingLeft: "1.25rem",
                                  paddingRight: "1.25rem",
                                  border: "none",
                                  borderRadius: "0.25rem",
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>

                {errors.place && (
                  <div
                    style={{
                      marginTop: "1rem",
                      color: colors.yellow,
                      fontWeight: 700,
                    }}
                  >
                    {errors.place.message}
                  </div>
                )}
              </div>
            </div>

            <ReactResizeDetector
              handleWidth
              render={({ width }) => (
                <ul
                  style={{
                    position: "relative",
                    zIndex: 2,
                    display: "grid",
                    gridTemplateColumns:
                      375 > width
                        ? "100%"
                        : `repeat(auto-fill, minmax(375px, 1fr))`,
                    gridGap: "1rem",
                  }}
                >
                  {sortBy(
                    data.allVacancy.edges,
                    vacancy => parseInt(vacancy.node.startDate.substr(6,4) + '' + vacancy.node.startDate.substr(3,2) + '' + vacancy.node.startDate.substr(0,2))
                  ).reverse()
                    .filter(edge =>
                      vacancyIdsPassingFilters.includes(edge.node.vacancyId)
                    )
                    .slice(0, numVisible)
                    .map(({ node }) => (
                      <Link
                        to={`/vacatures/${node.slug}/`}
                        key={node.vacancyId}
                        style={{
                          backgroundColor: colors.white,
                          display: "block",
                        }}
                        css={`
                          box-shadow: 0px 5px 20px 0px rgba(0, 0, 0, 0.03);
                          transition: all 0.15s ease;

                          &:hover {
                            transform: translateY(-2px);
                            box-shadow: 0px 5px 20px 0px rgba(0, 0, 0, 0.09);
                          }
                        `}
                      >
                        <li
                          style={{
                            padding: "1rem",
                            display: "flex",
                            flexDirection: "column",
                            height: "100%",
                          }}
                        >
                          {place && (
                            <div
                              style={{
                                marginBottom: "0.25rem",
                                opacity: 0.5,
                                fontSize: fontSizes[1],
                              }}
                            >
                              &plusmn;{" "}
                              {getDistanceFromLatLonInKm(
                                place.lat,
                                place.lon,
                                node.lat,
                                node.lon
                              ).toFixed()}{" "}
                              km
                            </div>
                          )}

                          <div style={{ display: "flex" }}>
                            <p style={{ marginRight: "1rem" }}>{node.title}</p>
                            <div
                              style={{
                                alignSelf: "center",
                                marginLeft: "auto",
                              }}
                            >
                              <button
                                style={{
                                  background: "none",
                                  border: "none",
                                  display: "block",
                                  cursor: "pointer",
                                  outline: "none",
                                }}
                              >
                                <BlobButton />
                              </button>
                            </div>
                          </div>
                        </li>
                      </Link>
                    ))}
                </ul>
              )}
            />

            {data.allVacancy.edges.length > numVisible && (
              <Button
                onClick={() => setNumVisible(prev => prev + 12)}
                style={{
                  display: "block",
                  marginLeft: "auto",
                  marginRight: "auto",
                  marginTop: "2rem",
                }}
              >
                Toon meer vacatures
              </Button>
            )}

            <Img
              style={{
                position: "absolute",
                zIndex: 1,
                bottom: 0,
                right: "-30rem",
              }}
              fixed={data.bread.childImageSharp.fixed as FixedObject}
            />

            <Img
              style={{
                position: "absolute",
                zIndex: 1,
                bottom: "8rem",
                left: "-9rem",
              }}
              fixed={data.pie.childImageSharp.fixed as FixedObject}
            />
          </Center>
        </section>

        <section style={{ paddingTop: "3rem", paddingBottom: "3rem" }}>
          <Center>
            <h2 style={{ marginBottom: "0.5rem" }}>werkenbijeenbakker.nl</h2>
            <p style={{ marginBottom: "2rem" }}>
              Werkenbijeenbakker.nl is onderdeel van Het Ambacht. Wij zijn
              altijd op zoek naar mensen die in de bakkersbranche willen werken.
              Of je nu 18 jaar of 65 jaar bent, het maakt niet uit want wij
              hebben een divers aanbod bakker vacatures. Want werken bij een
              bakkerij betekent niet alleen brood bakken. We hebben natuurlijk
              ook verkopers, inpakkers, productiemedewerkers en banketbakkers
              nodig.
            </p>

            <h2 style={{ marginBottom: "0.5rem" }}>Uitzendbureau bakker</h2>
            <p>
              Door ons grote netwerk in de bakkersbranche hebben wij door heel
              Nederland vacatures open staan. Wij zoeken graag in oplossingen,
              niet in problemen. Wij zijn dan ook hét uitzendbureau voor
              bakkers. Meld je vandaag nog aan via onderstaand
              inschrijfformulier of solliciteer op één van onze vacatures in de
              vacaturebank.
            </p>
          </Center>
        </section>

        <section
          style={{
            backgroundColor: colors.yellow,
            paddingTop: "3rem",
            paddingBottom: "3rem",
            overflow: "hidden",
            position: "relative",
          }}
        >
          <Img
            fluid={data.slicedBread.childImageSharp.fluid as FluidObject}
            style={{
              position: "absolute",
              bottom: 0,
              left: "26vw",
              width: "45vw",
              minWidth: "25rem",
            }}
          />

          <Center style={{ position: "relative" }}>
            <div style={{ overflow: "hidden" }}>
              <div
                style={{
                  margin: "-1rem -2rem",
                  display: "flex",
                  flexWrap: "wrap",
                }}
              >
                <div
                  style={{
                    margin: "1rem 2rem",
                    flexBasis: "20rem",
                    flexGrow: 1,
                  }}
                >
                  <h2 style={{ color: colors.white }}>
                    <div style={{ fontSize: fontSizes[6] }}>
                      Open
                      <br />
                      sollicitatie
                      <br />
                      bakkerij
                    </div>
                    <div
                      style={{ transform: "rotate(-3deg) translateY(-1rem)" }}
                    >
                      —————
                    </div>
                  </h2>

                  <p style={{ marginBottom: "2rem" }}>
                    Op zoek naar een baan in een bakkerij maar zit jouw vacature
                    er nu even niet tussen? Schrijf je dan nu in via het
                    formulier en stuur jouw open sollicitatie. Zo blijf je op de
                    hoogte van de laatste bakker vacatures en gaan wij op zoek
                    naar jouw ideale baan.
                  </p>

                  <div
                    style={{
                      position: "relative",
                    }}
                    css={`
                      display: inline-block;
                      left: 1rem;

                      @media (max-width: 50rem) {
                        left: 0;
                        display: block;
                        margin-left: auto;
                        margin-right: auto;
                        margin-bottom: 1rem;
                        transform: scale(0.9);
                      }
                    `}
                  >
                    <svg
                      width={107}
                      height={105}
                      viewBox="0 0 107 105"
                      style={{ marginLeft: "auto", marginRight: "auto" }}
                    >
                      <path
                        d="M100.33 28.882c5.48 12.946 8.11 28.377 3.79 42.655-4.32 14.279-15.583 27.381-27.986 31.573-12.422 4.185-25.947-.578-39.247-5.951-13.3-5.374-26.339-11.344-32.423-23.005-6.085-11.662-5.2-28.958 1.956-41.238 7.147-12.258 20.582-19.493 33.588-24.971C53.003 2.463 65.604-1.298 76.05 2.07c10.465 3.376 18.801 13.865 24.28 26.812z"
                        fill={colors.white}
                      />
                    </svg>

                    <div
                      style={{
                        position: "absolute",
                        width: "100%",
                        height: "100%",
                        top: "0.5rem",
                        left: 0,
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <p
                        style={{
                          textAlign: "center",
                          fontFamily: fontStacks.prequelDemo,
                          lineHeight: "1.25",
                        }}
                      >
                        <span style={{ whiteSpace: "nowrap" }}>
                          Voor bakkers,
                        </span>
                        <br />
                        door bakkers
                        <br />
                        <span
                          style={{
                            display: "inline-block",
                            transform: "rotate(-3deg)",
                          }}
                        >
                          ————
                        </span>
                      </p>
                    </div>
                  </div>
                </div>

                <div
                  style={{
                    margin: "1rem 2rem",
                    flexBasis: "20rem",
                    flexGrow: 1,
                  }}
                >
                  <div
                    id="open-application"
                    style={{
                      maxWidth: "30rem",
                      marginLeft: "auto",
                      marginRight: "auto",
                      borderRadius: "1.5rem",
                      backgroundColor: colors.white,
                      paddingTop: "2rem",
                      paddingBottom: "1rem",
                      paddingLeft: "1rem",
                      paddingRight: "1rem",
                    }}
                  >
                    <Form
                      header={
                        <div style={{ textAlign: "center" }}>
                          <h2>Solliciteer nu!</h2>
                          <p>Alle velden zijn verplicht</p>
                        </div>
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
          </Center>
        </section>
      </main>
    </>
  );
};

export default IndexPage;
